import { useState, useMemo, useEffect } from 'react';
import { useFoundationData } from './sanity/hooks';
import Map from './components/map/Map';
import Table from './components/table/table';
import Municipality from './components/municipality/Municipality';
import Foundation from './components/foundation/Foundation';
import Years from './components/years/Years';
import styles from './styles/styles.scss';

/* const FOUNDATION_IDS = {
  klepp: 'bf62a804-12d1-48d6-b7fe-5d58658dcec7',
  time: '0a7daccd-23aa-40c2-a704-238b1bed2cf0',
  sparebank: '1b79bdc1-1ed0-4787-99df-13cd73053f78'
}; */

// Iframe to use on website
// <iframe src="https://js-sponsoratkart-staging.grensesnitt.app?stiftelse=0a7daccd-23aa-40c2-a704-238b1bed2cf0" style="border:none;min-height:900px;width:100%;"></iframe>

function App({foundationId}) {
  const queryParameters = new URLSearchParams(window.location.search);
  const foundationIdFromQuery = queryParameters.get('stiftelse')
    ? queryParameters.get('stiftelse')
    : (foundationId ? foundationId : '');

  const { data } = useFoundationData(foundationIdFromQuery);
  const [activeMunicipality, setActiveMunicipality] = useState('alle');
  const [activeYear, setActiveYear] = useState('alle');
  const [activeFoundation, setActiveFoundation] = useState('alle');
  const [selectedHandoutKey, setSelectedHandoutKey] = useState(null);
  const [mapKey, setMapKey] = useState(0);

  const handoutsFiltered = useMemo(() => {
    if (!data) return [];
    if (!data[0].years) return [];

    const dataForActiveFoundations = data.filter((foundation) => {
      if (foundationIdFromQuery) {
        return foundation._id === foundationIdFromQuery;
      } else if (activeFoundation !== 'alle') {
        return foundation._id === activeFoundation;
      } else {
        return true;
      }
    });

    const handoutsForActiveYears = dataForActiveFoundations
      .map((foundation) => foundation.years)
      .flat()
      .filter((year) => {
        if (activeYear !== 'alle') {
          return year.year === +activeYear;
        } else {
          return true;
        }
      })
      .map((year) => year?.handouts)
      .flat();

    const handoutsForMunicipality = handoutsForActiveYears.filter((handout) => {
      if (activeMunicipality !== 'alle') {
        return (
          handout.organisation?.municipality?.name?.toLowerCase() ===
          activeMunicipality
        );
      } else {
        return true;
      }
    });

    return handoutsForMunicipality;
  }, [
    data,
    activeYear,
    activeMunicipality,
    activeFoundation,
    foundationIdFromQuery,
  ]);

  // Reset selected handout when changing municipality or year
  useEffect(() => {
    setSelectedHandoutKey(null);
  }, [activeMunicipality, activeYear]);

  useEffect(() => {
    setMapKey((prev) => prev + 1);
  }, [handoutsFiltered]);

  return (
    <div className="outer-container">
      <div className="button-container">
        {foundationIdFromQuery === '' && (
          <Foundation
            foundationData={data}
            activeFoundation={activeFoundation}
            setActiveFoundation={setActiveFoundation}
          />
        )}
        <Municipality
          foundationData={data}
          activeMunicipality={activeMunicipality}
          setActiveMunicipality={setActiveMunicipality}
        />
      </div>
      <Map
        mapKey={mapKey}
        foundationData={data}
        handoutsFiltered={handoutsFiltered}
        selectedHandoutKey={selectedHandoutKey}
        setSelectedHandoutKey={setSelectedHandoutKey}
      />
      <Years
        foundationData={data}
        activeYear={activeYear}
        setActiveYear={setActiveYear}
      />
      <Table
        handouts={handoutsFiltered}
        selectedHandoutKey={selectedHandoutKey}
        setSelectedHandoutKey={setSelectedHandoutKey}
      />
    </div>
  );
}

export default App;
