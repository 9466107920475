import styles from './InformationModal.module.scss';
import Images from './Images/Images';

const InformationModal = ({ organisation }) => {
  const totalAmount = organisation.handouts?.reduce(
    (acc, cur) => acc + cur.amount,
    0
  );
  const formatNumber = new Intl.NumberFormat('no', {
    currency: 'nok',
  });
  return (
    <section className={styles.container}>
      {organisation.images?.length && <Images images={organisation.images} />}
      <div className={styles.innercontainer}>
        <h2>{organisation.name}</h2>
        <p>{organisation.description}</p>
        <span className={styles.divider}></span>
        <h3>Gaver tildelt</h3>
        <ul>
          {organisation.handouts.map((handout, index) => (
            <li key={index}>
              <p>{handout.year}</p>
              <p>
                kr.<span>{formatNumber.format(handout.amount)}</span>
              </p>
              <p>{handout.purpose}</p>
            </li>
          ))}
        </ul>
        <span className={styles.divider}></span>
        <div className={styles.total}>
          <div>
            <p>Totalt</p>
            <p>
              kr.<span>{formatNumber.format(totalAmount)}</span>
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformationModal;
